body {
  background-color: #f3f4fa;
  margin: 0 auto;
  max-width: 1000px;
}

html, body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body, input, button, textarea {
  font-family: Georgia, Helvetica;
  font-size: 17px;
  color: #005064;
}
h1 {
  margin-top: 20px;
  text-align: center;
}

h3 {
  background-color: hsla(0, 0%, 0%, 0.2);
  padding: 20px;
  text-align: center;
}

h4 {
  padding: 5px 20px;
  text-align: center;
  color: black;
  margin: 5px 0;
}

h4 a {
  color: black;
}

h4 a:hover {
  color: blue;
}

a,
a:hover {
  color: #ecf0f1;
}

pre {
  background-color: #ead9d9;
}
pre code {
  color: #111;
  font-size: 18px;
}

pre {
  overflow-x: auto;
}

label {
  display: block;
  margin-bottom: 15px;
}

sub {
  display: block;
  margin-top: -10px;
  margin-bottom: 15px;
  font-size: 11px;
  font-style: italic;
}

ul {
  margin: 0;
  padding: 0;
}

.parent {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 20px 0;
  padding: 20px;
}

input {
  border: none;
  outline: none;
  background-color: #ecf0f1;
  padding: 10px;
  color: #14204f;
  border: 0;
  margin: 5px 0;
  display: block;
  width: 100%;
}

button {
  background-color: #ecf0f1;
  color: #14204f;
  border: 0;
  padding: 18px 12px;
  margin-left: 6px;
  cursor: pointer;
  outline: none;
}

button:hover {
  background-color: #e74c3c;
  color: #ecf0f1;
}

.a_line {
  display: block;
  width: 100%;
}

.nsg-tag {
  border-color: #14204f;
}

.inline {
  display: inline-block;
}

.gh-fork {
  position: fixed;
  top: 0;
  right: 0;
  border: 0;
}

.autofruit {
  width: 16px;
  margin-right: 3px;
}

#ddl {
  cursor: pointer;
  padding: 10px;
  background-color: rgba(0,0,0,0.1);
}

.examples {
  background-color: rgba(0,0,0,0.1);
}

/* test class name */

.class_1 {
  height: 300px!important;
}

.class_2 {
  height: 200px!important;
}

.PinkQ {
  color: #E87A90;
}